import React from 'react'
import { Link } from 'gatsby'
import * as Icon from 'react-feather'

import Logo from '../../assets/images/logo.png'

const Navbar = () => {
  const [menu, setMenu] = React.useState(true)

  const toggleNavbar = () => {
    setMenu(!menu)
  }

  React.useEffect(() => {
    let elementId = document.getElementById('header')
    document.addEventListener('scroll', () => {
      if (window.scrollY > 170) {
        elementId.classList.add('is-sticky')
      } else {
        elementId.classList.remove('is-sticky')
      }
    })
    window.scrollTo(0, 0)
  })

  const classOne = menu
    ? 'collapse navbar-collapse'
    : 'collapse navbar-collapse show'
  const classTwo = menu
    ? 'navbar-toggler navbar-toggler-right collapsed'
    : 'navbar-toggler navbar-toggler-right'

  return (
    <header id='header' className='headroom'>
      <div className='startp-nav'>
        <div className='container'>
          <nav className='navbar navbar-expand-md navbar-light'>
            <Link to='/' onClick={toggleNavbar} className='navbar-brand'>
              <img src={Logo} alt='logo' />
            </Link>

            <button
              onClick={toggleNavbar}
              className={classTwo}
              type='button'
              data-toggle='collapse'
              data-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='icon-bar top-bar'></span>
              <span className='icon-bar middle-bar'></span>
              <span className='icon-bar bottom-bar'></span>
            </button>

            <div className={classOne} id='navbarSupportedContent'>
              <ul className='navbar-nav ms-auto'>
                <li
                  className='nav-item'
                  style={{ padding: '15px 100px 15px 0' }}
                >
                  <Link
                    to='#'
                    activeClassName='active'
                    onClick={e => e.preventDefault()}
                    className='nav-link'
                  >
                    Home <Icon.ChevronDown />
                  </Link>

                  <ul className='dropdown-menu' style={{}}>
                    {/* <li className='nav-item'>
                      <Link
                        to='/machine-learning'
                        activeClassName='active'
                        onClick={toggleNavbar}
                        className='nav-link'
                      >
                        Machine Learning
                      </Link>
                    </li> */}

                    <li className='nav-item'>
                      <Link
                        // to='/jupyterlab-service'
                        to='/'
                        activeClassName='active'
                        onClick={toggleNavbar}
                        className='nav-link'
                      >
                        JupyterLab Service
                      </Link>
                    </li>

                    <li className='nav-item'>
                      <Link
                        to='/faq'
                        activeClassName='active'
                        onClick={toggleNavbar}
                        className='nav-link'
                      >
                        FAQ
                      </Link>
                    </li>

                    <li className='nav-item'>
                      <Link
                        to='/about'
                        activeClassName='active'
                        onClick={toggleNavbar}
                        className='nav-link'
                      >
                        About Us
                      </Link>
                    </li>

                    {/* <li className='nav-item'>
                      <Link
                        to='/digital-twins'
                        activeClassName='active'
                        onClick={toggleNavbar}
                        className='nav-link'
                      >
                        Digital Twins (TBD)
                      </Link>
                    </li> */}
                  </ul>
                </li>

                {/* <li className='nav-item'>
                  <Link
                    to='#'
                    onClick={e => e.preventDefault()}
                    className='nav-link'
                  >
                    Projects <Icon.ChevronDown />
                  </Link>

                  <ul className='dropdown-menu'>
                    <li className='nav-item'>
                      <Link
                        to='/coming-soon'
                        activeClassName='active'
                        onClick={toggleNavbar}
                        className='nav-link'
                      >
                        DONUT (TBD)
                      </Link>
                    </li>

                    <li className='nav-item'>
                      <Link
                        to='/coming-soon'
                        activeClassName='active'
                        onClick={toggleNavbar}
                        className='nav-link'
                      >
                        PREDIS (TBD)
                      </Link>
                    </li>
                  </ul>
                </li> */}

                {/* <li className='nav-item'>
                  <Link
                    to='/about'
                    activeClassName='active'
                    // onClick={toggleNavbar}
                    className='nav-link'
                  >
                    About
                  </Link>
                </li> */}

                {/* Blog */}

                {/* <li className='nav-item'>
                  <Link
                    to='#'
                    onClick={e => e.preventDefault()}
                    className='nav-link'
                  >
                    Blog <Icon.ChevronDown />
                  </Link>

                  <ul className='dropdown-menu'>
                    <li className='nav-item'>
                      <Link
                        to='/blog'
                        activeClassName='active'
                        onClick={toggleNavbar}
                        className='nav-link'
                      >
                        Blog DEMO
                      </Link>
                    </li>

                    <li className='nav-item'>
                      <Link
                        to='/blog-article'
                        activeClassName='active'
                        onClick={toggleNavbar}
                        className='nav-link'
                      >
                        Blog Article DEMO
                      </Link>
                    </li>

                    <li className='nav-item'>
                      <Link
                        to='/author'
                        activeClassName='active'
                        onClick={toggleNavbar}
                        className='nav-link'
                      >
                        Blog Author DEMO
                      </Link>
                    </li>
                  </ul>
                </li> */}

                {/* <li className='nav-item'>
                  <Link
                    to='/contact'
                    activeClassName='active'
                    onClick={toggleNavbar}
                    className='nav-link'
                  >
                    Contact
                  </Link>
                </li> */}
              </ul>
            </div>

            {/* <div className='others-option'>
              <Link to='/cart' className='cart-wrapper-btn'>
                <Icon.ShoppingCart />
                <span>0</span>
              </Link>

              <Link to='/contact' className='btn btn-light'>
                Support
              </Link>

              <Link to='/login' className='btn btn-primary'>
                Login
              </Link>
            </div> */}
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Navbar
